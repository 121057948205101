import logo from './logo.svg';
import './App.css';
import Notification from './components/Notification';


function App() {
  return (
    <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Push Test
      </p>
      <p id="token" style={{ width: '80%', fontSize: '18px', wordBreak: 'break-word', lineHeight: '1.6' }}></p>
      {/* <a className="App-link" href="https://reactjs.org" target="_bla''nk" rel="noopener noreferrer">
        Learn React
      </a> */}
      <Notification />
      </header> 
    </div>
  );
}
export default App;


