import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: "AIzaSyC39m-UZ5v3n_YmFNHPYueRY_JQ7tbuYdc",
    authDomain: "pushtest-98d55.firebaseapp.com",
    projectId: "pushtest-98d55",
    storageBucket: "pushtest-98d55.appspot.com",
    messagingSenderId: "338846364282",
    appId: "1:338846364282:web:58c0b393286fc7f5f941c3",
    measurementId: "G-EF46HYL2WG"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const requestPermission = () => {
    console.log("Requesting User Permission......");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted."); 
        return getToken(messaging, { vapidKey: `BHjQVRBIAn_aJx8-heb_rsIvcmt5eQAHYs5HlyNICN81Xxp4hyVTDmCvmKXq9M_kDj5mBJV6yzSjcVyZJT0rYXE` })
          .then((currentToken) => {
            if (currentToken) {
              console.log('Client Token: ', currentToken);
              document.getElementById('token').textContent = 'Client Token: ' + currentToken;

            } else {
              
              console.log('Failed to generate the app registration token.');
            }
          })
          .catch((err) => {
            console.log('An error occurred when requesting to receive the token.', err);
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }
requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});