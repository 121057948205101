import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../firebase';

function Notification() {
  const [notification, setNotification] = useState({ title: '', body: '' });

  useEffect(() => {
    requestPermission();

    let unsubscribe;
    onMessageListener().then(payload => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
        duration: 60000,
        position: 'top-right' // corrected position property
      });

      // Assuming `onMessageListener` returns a function for unsubscribing.
      unsubscribe = payload.unsubscribe;
    }).catch(err => console.log('Error setting up notification listener:', err));

    return () => {
      if (unsubscribe) {
        unsubscribe().catch(err => console.log('Failed to unsubscribe:', err));
      }
    };
  }, []);

  return (
    <div>
      <Toaster />
    </div>
  );
}

export default Notification;
